main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

article {
	max-width: 1100px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr; /* fractions */
	grid-gap: 50px;
	margin: 60px auto;	/* place article in the center!! */
}


article img {
	width: 100%;
	height: auto;
}